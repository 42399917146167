import type { MqttContainer, MqttValue } from "./types"

// pedal difference indicates if the pedal ratio is active
export const cranksetPedalDifference: MqttValue = {
    title: 'pedal difference enabled',
    topic: 'bikefitting/crankset/pedal_diff',
    unit: '', // no unit as it is a boolean value
}

// only available when pedal difference is set to true
export const cranksetPedalRatio: MqttValue = {
    title: 'pedal ratio',
    topic: 'bikefitting/crankset/pedal_ratio',
    unit: '%',
}

export const cranksetPedal: MqttContainer = {
    title: "pedal",
    prefix: 'pedal_',
    values: [
        cranksetPedalDifference,
        cranksetPedalRatio,
    ],
}

export const cranksetTorqueLeft: MqttValue = {
    title: 'torque left',
    topic: 'bikefitting/crankset/left_torque',
    unit: 'Nm',
    decimalPlaces: 1,
}

export const cranksetTorqueRight: MqttValue = {
    title: 'torque right',
    topic: 'bikefitting/crankset/right_torque',
    unit: 'Nm',
    decimalPlaces: 1,
}

export const cranksetTorque: MqttContainer = {
    title: 'torque',
    prefix: '',
    values: [
        cranksetTorqueLeft,
        cranksetTorqueRight,
    ],
}

export const cranksetSmoothnessLeft: MqttValue = {
    title: 'smoothness left',
    topic: 'bikefitting/crankset/left_smoothness',
    unit: '%',
}

export const cranksetSmoothnessRight: MqttValue = {
    title: 'smoothness right',
    topic: 'bikefitting/crankset/right_smoothness',
    unit: '%',
}

export const cranksetSmoothness: MqttContainer = {
    title: 'smoothness',
    prefix: '',
    values: [
        cranksetSmoothnessLeft,
        cranksetSmoothnessRight,
    ],
}

export const cranksetCadence: MqttValue = {
    title: 'cadence',
    topic: 'bikefitting/crankset/cadence',
    unit: 'RPM',
}

export const cranksetAccumulatedPower: MqttValue = {
    title: 'accumulated power',
    unit: 'W',
    topic: 'bikefitting/crankset/accumulated_power',
}

export const cranksetInstantaneousPower: MqttValue = {
    title: 'power',
    unit: 'W',
    topic: 'bikefitting/crankset/instantanious_power',
}

export const cranksetDefinitions: MqttContainer = {
    title: 'Crankset',
    prefix: 'crankset/',
    values: [
        cranksetPedal,
        cranksetCadence,
        cranksetAccumulatedPower,
        cranksetInstantaneousPower,
        cranksetTorqueLeft,
        cranksetTorqueRight,
        cranksetSmoothness,
    ],
}

export default cranksetDefinitions
