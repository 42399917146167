import { MqttContainer, MqttValue, instanceOfMqttValue } from "./types"
import mqttDefinitions from "./bikefitting"
import { MqttClient } from 'mqtt'

/* Creates the topic by combining an array of definitions for MqttContainers and an MqttValue 
 * e.g.: createTopicFromValuesArrays([mqttDefinitions, cranksetDefinitions, cranksetPower]);
 * @returns topic in string format
*/
export function createTopicFromValuesArrays(definitions: (MqttContainer | MqttValue)[]): string {
    const defs = [...definitions]
  // if the root is not present add it
    if (instanceOfMqttValue(defs[0]) || defs[0].prefix !== mqttDefinitions.prefix) {
        defs.unshift(mqttDefinitions)
    }

    const topic = defs.reduce((topicAccumulator, currentValue) => {
        if (instanceOfMqttValue(currentValue)) {
            return topicAccumulator + currentValue.topic
        }
        return topicAccumulator + currentValue.prefix
    }, '')
    return topic
}

export type ConversionDirection = 'submit' | 'receive';

export function mqttValueConversion(value: number | undefined, conversionValue: number | undefined, direction: ConversionDirection) {
    const conv = conversionValue || 1
    let returnValue
    if (value && value > 0) {
        returnValue = direction === 'submit' ? value / (conv) : value * (conv)
    } else {
        returnValue = 0
    }
    return Math.round(returnValue)   
}

// export function publishMqttMessage(client: MqttClient | undefined, mqttDefinition: MqttValue, topic: string, value: number) {
//     if (mqttDefinition?.preSubmitFunction) {
//         const preSubmitOutput = mqttDefinition.preSubmitFunction(mqttDefinition, topic, ssaz(value))
//         client?.publish(`${preSubmitOutput.topic}`, preSubmitOutput.value)
//         console.log(`topic: ${preSubmitOutput.topic}, value: ${preSubmitOutput.value}`)
//     } else {
//         const convertedValue = ssaz(mqttValueConversion(value, mqttDefinition.conversionValue, 'submit'))
//         const data =
//       JSON.stringify({
//           value: convertedValue,
//       })

//         client?.publish(
//             `${topic}/set`,
//             data,
//         )
//         console.log(`topic: ${topic}, value: ${data}`)
//     }
// }

// export function getMqttValueFromMessage(message: IMessage) {
//     if (typeof message.message === "string") {
//         const value = JSON.parse(message.message)?.value
//         return value
//     } else {
//         return undefined
//     }
// }

// export function getMqttConvertedValueFromMessage(message: IMessage, mqttDefinition: MqttValue): number | undefined {
//     let value = getMqttValueFromMessage(message)
//     if (!value) {
//         return value
//     }
//     if (typeof value === 'string') {
//         value = parseInt(value)
//     }
//     return mqttValueConversion(value, mqttDefinition.conversionValue, 'receive')
// }
