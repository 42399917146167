
import type { MqttMessage } from "./types"
import { mqttValueConversion } from './helpers'

/* converts the motorcontroller prefix and topic to the output the mqtt api expects
 *  e.g. the topic: bikefitting/motorcontroller/set
 *  and the value {channel: number, value: number}
 */
export function motorControllerPreSubmit(message: MqttMessage, conversionValue: number | undefined) {

    const totalTopicSplit = message.topic.split('/')
    totalTopicSplit.pop() // remove setpoint
    const channel = `${totalTopicSplit.pop()}`
    const setTopic = `${totalTopicSplit.join('/')}/set`
    
    // convert the value
    const finalValue = mqttValueConversion(message.payload, conversionValue, 'submit')
    const setValue = JSON.stringify({
        value: finalValue,
        channel: Number.parseInt(channel),
    })

    return ({
        topic: setTopic,
        payload: setValue,
    })
}

export function ssaz(input: number) {
    return (input > 0) ? input : 0
}

export const motorConversionValue = 2 / 20 // 20 rotations is 2mm
